const messages = {
  cs: {
    parameters: {
      yes: 'A',
    },
    com: {
      phase: {
        label: 'Label',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Kód produktu',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Výrobce',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Produktové číslo',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Záruka',
          months: 'měsíců',
        },
      },
      totalAmount: {
        label: 'Celkem',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ dalších ({number}).',
    },
    orders: {
      actions: {
        all: 'Všechny objednávky',
      },
      myOrdersScoringBlog: {
        title: 'Moje objednávky',
      },
      ordersList: 'Objednávky',
      phase: {
        1: 'Přijatá',
        '01-new': 'Přijatá',
        10: 'Otevřená',
        20: 'Čeká na platbu',
        30: 'Zaplacená',
        98: 'Dokončená',
        99: 'Zrušená',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Číslo balíku',
            phase: 'Stav',
            paid: 'Zaplaceno',
            total: 'Bez DPH',
            totalWithTax: 'Celkem',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Košík',
        buyButton: 'Odeslat objednávku',
        emptyBasket: 'Váš nákupní košík je prázdný',
        buyThere: 'Již jsem zde nakupoval',
        login: 'Přihlášení',
        notFill: 'Údaje o doručení nejsou vyplněny',
        fill: 'Vyplňte informace',
        change: 'Změňte data',
        popupButton: 'Zobrazit na mapě',
        payment: {
          title: 'ZPŮSOB PLATBY',
          free: 'Zdarma',
          changePaymentBtn: 'ZMĚNA PLATBY',
        },
        shipping: {
          title: 'ZPŮSOB DORUČENÍ',
          free: 'Zdarma',
          changeShippingBtn: 'ZMĚNA DOPRAVY',
          popup: {
            title: 'Informace',
            text: 'Doručení na vaši adresu kurýrem {service}',
          },
        },
      },
      actions: {
        all: 'Nákupní košíky',
      },
      myBasketsScoringBlog: {
        title: 'Uložené nákupní košíky',
      },
      cartSummaryParameters: {
        caption: 'Parametry',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Popis',
      parameters: 'Parametry',
      package: 'Obsah balení',
      fotoAndVideo: 'Obrázky a video',
      alternatives: 'Alternativy',
      alternativesText: 'Bohužel v současné době nemáme náhradu za vámi vybraný produkt.',
      questions: 'Dotaz',
      productDescriptionNotAvailable: 'Popis produktu není k dispozici',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: {
        title: 'Nenechte si ujít speciální nabídky',
        inputText: 'Vaše emailová adresa',
        button: 'Odeslat',
      },
    },
    globalNavigation: {
      home: 'Domů',
      shopping: 'Nakupovat',
      orders: 'Objednávky',
      shoppingLists: 'Nákupní seznamy',
      articles: 'Novinky',
    },
    homePageWhy: {
      0: {
        title: 'Nakupujte bezpečně',
        content: 'Jsme ověřeným zákazníkem portálu Heureka a vlastníme certifikát APEK.',
      },
      1: {
        title: 'Výdejní místa',
        content: 'Zboží si také můžete vyzvednout na více než 2000 výdejních místech.',
      },
      2: {
        title: 'Zboží ve vlastním skladu',
        content: '98% veškerého zboží máme skladem a je připraveno k okamžité expedici.',
      },
      3: {
        title: 'Doprava zdarma',
        content: 'Při nákupu celé řady produktů z naší nabídky máte dopravu ZDARMA.',
      },
      title: 'PROČ U NÁS?',
    },
    startPage: {
      title: 'Úvodní strana',
      gotoShopping: 'Nakupovat',
    },
    shoppingCart: {
      actions: {
        buy: 'PŘIDAT DO KOŠÍKU',
      },
      buyBtn: 'PŘIDAT DO KOŠÍKU',
      alternatives: 'ALTERNATIVA',
      shipping: {
        changePoint: 'Změnit',
      },
      agreements: {
        termsRequired: 'Musíte souhlasit s obchodními podmínkami.',
      },
      validation: {
        someProductNotAvailable: 'Některé produkty nejsou skladem v dostatečném množství.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Kód produktu:',
    },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Doporučujeme',
        name: 'Jméno',
        priceAsc: 'Od nejvyšší ceny',
        priceDesc: 'Od nejnižší ceny',
      },
    },
    orderStatuses: {
      storno: 'Storno',
      synchronized: 'Odesláno',
      waiting: 'Čekání na synchronizaci',
    },
    orderPreview: {
      items: 'Produkty',
    },
    cartRegisterForm: {
      title: 'Registrační formulář',
    },
    cartPayment: {
      title: 'ZPŮSOB PLATBY',
    },
    cartPreSummaryPage: {
      title: 'Přehled košíku',
      actions: {
        nextStep: 'Souhlasím a pokračuji',
      },
    },
    cartCompanyForm: {
      description: 'Vyplňte, pokud nakupujete zboží pro firmu',
    },
    cartDeliveryForm: {
      description: 'Vyplňte, pokud se dodací adresa liší od fakturační adresy',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Kdy obdržím objednané zboží?',
    },
    productDetailStockInfo: {
      available: 'Produkt máme fyzicky v našem distribučním skladu.',
      external: 'Produkt je na cestě do našeho skladu',
      notAvailable: 'Zboží je dočasně nedostupné',
      labels: {
        free: 'DOPRAVA ZDARMA',
        showroom: 'VYSTAVENO V SHOWROOMU',
      },
    },
    productGifts: {
      btnName: 'DÁREK',
      giftValue: 'V hodnotě',
    },
    homepage: {
      productOfMonth: 'Produkty měsíce',
      catalogFavorite: 'Nejoblíbenější kategorie',
      hotTips: 'Horké tipy',
      recommended: 'Doporučujeme',
    },
    productQuestion: {
      firstName: 'Jméno',
      lastName: 'Příjmení',
      email: 'E-mail',
      subject: 'Produkt',
      question: 'Napište dotaz',
      gdpr: 'Odesláním dotazu souhlasím se zpracováním osobních údajů za účelem získání odpovědi.',
      gdprLink: 'odkaz na GDPR',
      button: 'Odeslat dotaz',
      subjectContent: 'Dotaz na produkt: {product}, kód: {sku}',
      title: 'Musíte souhlasit se zpracováním osobních údajů.',
      success: 'Formulář byl odeslán',
    },
    lastVisitedProducts: 'Navštívené produkty',
    productAlternativeProducts: 'Alternativy',
    productAccessoryProducts: 'Příslušenství',
    productReplacementProducts: 'Náhrady',
    productAccessories: 'Příslušenství',
    productReplacements: 'Náhrady',
    appProductDetailTabs: {
      description: 'Popis',
      parameters: 'Parametry',
      package: 'Obsah balení',
      query: 'Dotaz',
      media: 'Obrázky a video',
      alternatives: 'Alternativy',
      accessories: 'Příslušenství',
      replacements: 'Příslušenství',
      form: {
        enum: '!!!! ENUM HODNOTA',
      },
    },
    cartSummaryPage: {
      title: 'Shrnutí před objednávkou',
      actions: {
        continueShopping: 'Pokračovat v nákupu',
        saveOrder: 'Uložit objednávku',
      },
      expectedDeliveryDate: {
        label: 'Požadované datum dodávky',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Poznámka pro dopravce',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Platební brána',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Můj účet',
      content: 'Obsah',
      contactInfo: {
        newsletter: {
          title: 'Newsletter',
          content: 'Chci dostávat novinky',
        },
        title: 'kontaktní údaje',
      },
      orders: {
        title: 'Historie objednávek',
      },
      invoices: {
        title: 'Faktury',
      },
      myOrders: 'Objednávky',
      myInvoices: 'Faktury',
      logout: 'Odhlásit',
      login: 'Přihlášení',
      favorites: {
        title: 'Oblíbené',
      },
      changePassword: {
        title: 'Změna hesla',
        fields: {
          login: 'Login (nelze změnit)',
          originalPassword: 'Původní heslo',
          newPassword: 'Nové heslo',
          confirmNewPassword: 'Potvrďte heslo',
        },
        button: 'Uložit změny',
      },
    },
    orderSavedThankPage: {
      title: 'Děkujeme za vaši objednávku {orderNumber}',
      thankMessage: {
        email: 'info@ceskyvelkoobchod.cz',
        phoneNumber: '',
        template:
          'Zkontrolujte prosím svou e-mailovou schránku pro potvrzení objednávky. E-mail si uložte pro vlastní potřebu. Veškeré informace o vaší objednávce naleznete v sekci níže.',
      },
      order: 'Číslo objednávky:',
      payment: 'Platba dne:',
      cancelled: 'Zrušeno',
      accepted: 'Přijato',
      pending: 'Čekající',
      paid: 'Zaplaceno',
      actions: {
        back: 'Zpět na hlavní stránku',
        detail: 'Přehled objednávky',
        continue: 'Pokračovat v nákupu',
      },
      decline: 'Odmítnuto',
    },
    shoppingLists: {
      title: 'Nákupní seznam',
      actions: {
        saveAsShoppingList: 'Uložit nákupní seznam',
        importShoppingListToCart: 'Import',
      },
    },
    searchResultPage: {
      search: 'Hledaný výraz',
      results: 'Výsledky vyhledávání',
    },
    homePageArticles: {
      title: 'Aktuality',
      button: 'Zobrazit všechny články',
    },
    loginPage: {
      title: 'Přihlášení',
      forgotPassword: 'Resetovat heslo',
      email: 'E-mailem',
      sendEmail: 'Poslat email',
      info: 'Obdržíte e-mail s odkazem na změnu hesla',
      password: 'Heslo',
      notSame: 'Heslo se neshoduje',
      newPassword: 'Nové heslo',
      confirmPassword: 'Potvrďte heslo',
      changePassword: 'Změna hesla',
    },
    productPrice: {
      withoutTax: 'bez DPH',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Objednejte {today} a my vám odešleme během {minDays}–{maxDays} dnů.',
        today: 'Objednejte dnes do {time} a ještě dnes odešleme.',
        todayWord: 'dnes',
        tomorrow: 'Objednejte {today} a odešleme zítra.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Objednejte {today} a my vám odešleme {day}.',
          1: 'v pondělí',
          2: 'v úterý',
          3: 've středu',
          4: 've čtvrtek',
          5: 'v pátek',
          6: 'v sobotu',
          0: 'v neděli',
        },
      },
      allDescription: 'ÚPLNÝ POPIS PRODUKTU',
      demoProduct: {
        text: 'KOUPIT LEVNĚJI za:',
      },
      productNote: {
        title: 'KOUPIT LEVNĚJI za',
        note: 'Poznámka 2 – Dodatečný popis stavu nebo podobné informace o produktu, proč má slevu a co je na zboží...',
      },
      adornments: {
        notForSale: 'Není na prodej',
        usedProduct: 'Použité',
        freeGift: 'Dárek zdarma',
        freeShipping: 'Doprava zdarma',
        eol: 'Prodej ukončen',
        demo: 'Demo produkt',
        sale: 'Sleva',
      },
      disponibility: {
        primaryLessThan: 'SKLADEM méně než {count} kusů',
        primaryMoreThan: 'SKLADEM {count, plural, zero {jeden kus} other {{count} a více kusů}}',
        secondaryLessThan: 'SKLADEM v připravném skladu méně než {count} kusů',
        secondaryMoreThan:
          'SKLADEM v připravném skladu {count, plural, zero {jeden kus} other {{count} a několik kusů}}',
        external: 'Produkt je na cestě do našeho skladu',
        unavailable: 'Produkt není dostupný',
        showroom: 'Vystaveno v showroomu',
        soldout: 'Produkt je vyprodaný',
        'on-way': {
          d: '',
          m: 'Na cestě za {from, plural, one {minuta} two {minuty} other {minut}}',
          M: 'Na cestě {from, plural, one {měsíc} two {měsíce} few {měsíců} other {měsíců}}',
          dRange: 'Na cestě {to, plural, few {{from}-{to} dnů} other {{from}-{to} dnů}}',
          mRange: 'Na cestě {from}-{to, plural, one {den} few {dny} other {dnů}}',
          MRange: 'Na cestě {from}-{to, plural, one {měsíc} two {měsíce} few {měsíců} otherch {měsíců}}',
          date: '{datum}',
        },
        time: 'Na cestě pro {count, plural, zero {jeden kus} other {{count} a více kusů}}',
      },
    },
    comparePage: {
      title: 'Porovnat',
      removeButton: 'Odstranit z porovnání',
      nothing: 'Žádné produkty k porovnání',
    },
    filter: {
      reset: 'Resetujte filtr',
      search: 'Vyhledávání',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Podmínky objednávky',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'přidat do košíku',
      },
    },
    invoiceBlogPreview: {
      title: 'Moje účty',
      button: 'Všechny účty',
    },
    userProfilePage: {
      logout: 'Odhlásit',
      setting: 'Nastavení',
      passwordChange: 'Změna hesla',
    },
    appBarActionRegion: {
      changeRegion: 'Vyberte jazyk',
      label: 'OK',
    },
    subCategories: {
      title: 'Podkategorie',
    },
    aboutShoppingPage: {
      title: 'VŠE O NÁKUPU',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONICKÁ EVIDENCE VÝNOSŮ',
      informationDuty: 'INFORMAČNÍ POVINNOST',
      processingOfPersonalData: 'ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ',
      cashback: 'CASHBACK',
      delivery: 'DODÁVKA',
      availability: 'DOSTUPNOST',
      methodOfPayment: 'ZPŮSOB PLATBY',
      termsAndConditions: 'PRAVIDLA A PODMÍNKY',
      termsOfAppeal: 'PODMÍNKY ODVOLÁNÍ',
      contractwWithdrawal: 'ODSTOUPENÍ OD SMLOUVY',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Vaše daňové číslo bylo ověřeno, ceny jsou účtovány bez DPH.',
        viesNotVerified: 'Vaše daňové číslo nebylo ověřeno nebo nesplňujete podmínky pro nákup bez DPH.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'OSOBNÍ ÚDAJE',
      pleaseComplete: 'VYPLNIT',
      closeButton: 'Zavřít',
    },
    cartPromoCodes: {
      title: 'UPLATNIT PROMO KÓD',
      textFieldPlaceholder: 'PROMO KÓD',
      textFieldHelperText: 'Je možné uplatnit jen jeden promo kód na objednávku',
      applyPromoCodeButton: 'UPLATNIT',
    },
    cookiesBar: {
      agreeBtn: 'OK',
      title: 'UPOZORNĚNÍ O COOKIES NA NAŠEM WEBU',
      text: 'Naše společnost používá na tomto webu své vlastní soubory cookie (tzv. základní soubory cookie) a soubory cookie třetích stran. Na základě těchto souborů a zájmu přizpůsobujeme webové stránky tak, aby splňovaly očekávání zákazníků, optimalizovaly naše nabídky a předcházely riziku podvodu. Bez některých nezbytných souborů cookie nemůže web správně fungovat. Kliknutím na tlačítko „Přijmout vše“ souhlasíte s jejich používáním včetně informací o chování webu. Svůj souhlas můžete kdykoli odvolat.',
    },
    cartFormLogin: {
      btnSend: 'Poslat',
      button: 'Přihlásit se',
      title: 'přihlášení',
      forgottenPasswordBtn: 'Zapomněli jste heslo?',
      text1: 'Na zadanou e-mailovou adresu vám zašleme jednorázové heslo.',
      text2: 'Po přihlášení si nastavte nové heslo.',
      error: 'Nesprávné uživatelské jméno nebo heslo.',
    },
    cartSummartSection: {
      title: 'PŘEHLED OBJEDNÁVKY',
    },
    appCartSummarySubjectsList: {
      title: 'PRODUKT',
      text: 'POPIS',
    },
    newsletterText: {
      text1: 'Potřebujete poradit?  Tel: +420 722 093 063',
      text2: 'Volat nám můžete každý pracovní den od 8 do 16 hodin.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(bez DPH)',
      priceLabel: 'CENA',
      amount: 'MNOŽSTVÍ',
      totalPriceLabel: 'CELKEM',
      totalPrice: 'Celkem',
      submitButton: 'ODESLAT OBJEDNÁVKU',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Zdarma',
      amountTitle: 'ks',
    },
    sxCartNotes: {
      note: 'Neslouží k označení data dodání',
      addNote: 'Přidat poznámku',
    },
    productDetail: {
      promoCode: {
        title: 's promo kódem:',
        validTo: 'platí do:',
      },
      productSendLink: {
        copyLinkBtn: 'Zkopírujte odkaz',
        sendLinkByEmail: 'Pošlete odkaz emailem',
      },
      productDetailBarInformation: {
        addToFavourites: 'Přidat k oblíbeným',
        compareProduct: 'Porovnat produkt',
        sendLink: 'Poslat odkaz',
        serviceList: 'Seznam služeb',
        questions: 'Dotaz',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'NAKOUPIT NA FIRMU',
        otherDeliveryInfo: 'DORUČIT NA JINOU ADRESU',
        otherDeliveryInfoAlert: 'Plátce DPH nemůže pro doručování použít jinou adresu.',
      },
      companyNameLabel: 'Název firmy',
      firstNameLabel: 'Jméno',
      lastNameLabel: 'Příjmení',
      streetLabel: 'Ulice',
      cityLabel: 'Město',
      zipLabel: 'PSČ',
      companyIDLabel: 'IČO',
      countryLabel: 'Stát',
    },
    cartFormPersonal: {
      label: 'Doručovací adresa',
      firstNameLabel: 'Jméno',
      lastNameLabel: 'Příjmení',
      streetLabel: 'Ulice a číslo domu',
      pscLabel: 'PSČ',
      cityLabel: 'Město',
      countryLabel: 'Stát',
      phoneLabel: 'Telefonní číslo',
      emailLabel: 'Emailová adresa',
      passwordLabel: 'Heslo',
      controlPasswordLabel: 'Kontrolní heslo',
    },
    cartFormInvoice: {
      labelDesktop: 'ÚDAJE K PLATBĚ',
      labelMobile: 'kontaktní údaje',
      companyLabel: 'Firma',
      password: 'Heslo',
      confirmPassword: 'Ověření hesla',
      email: 'Emailová adresa',
      firstName: 'Jméno',
      lastName: 'Příjmení',
      countryLabel: 'Stát',
      phone: 'Telefonní číslo',
      phoneError: 'Telefonní číslo není správné',
      streetLabel: 'Ulice',
      streetNumberLabel: 'Číslo domu',
      zipLabel: 'PSČ',
      icoLabel: 'IČO',
      dicLabel: 'DIČ',
      country: 'Stát',
      cityLabel: 'Město',
      registration: 'Chci si otevřít účet',
      registrationNote: 'Otevřete si účet a můžete sledovat své objednávky, zásilky a platby.',
      button: 'Uložit změny',
    },
    page404: {
      title: 'Stránka bohužel neexistuje...',
      subTitle: 'Pokračujte na hlavní stránku.',
      button: 'Zpět na hlavní stránku.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Seřazeno podle:',
      byName: 'Název',
      byPrice: 'Cena',
      onlyStock: 'Pouze skladem',
      resetFilter: 'Zrušit filtry',
      displayCountOfProducts: 'Produkty:',
      search: 'Vyhledávání',
      clear: 'Vymazat',
      ok: 'Filtrovat',
    },
    priceRangeSliderField: {
      title: 'Cenové rozpětí',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtrovat podle',
    },
    productListMultipleFilterMobile: {
      title: 'FILTR',
    },
    cartCompany: {
      companyName: 'Společnost',
      regNo: 'IČO',
      taxNo: 'DIČ',
      taxNoPlaceholder: 'CZ0000000000',
      warningMessage: {
        warning: 'Důležité.',
        message: 'V případě doručení na jinou adresu než je adresa společnosti bude objednávka zpoplatněna DPH.',
      },
    },
    searchResultsPage: {
      match: 'Našli jsme {resultCountFormatted} výsledků pro {searchText}.',
      nomatch: 'Pro {searchText} nebyly nalezeny žádné výsledky.',
      matchingCategories: 'Nalezené kategorie',
      button: 'Zpět na hlavní stránku.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Počet vybraných produktů: {total}',
    },
    cartDelivery: {
      firstName: 'Jméno',
      lastName: 'Příjmení',
      companyName: 'Společnost',
      streetLabel: 'Ulice',
      streetNumberLabel: 'Číslo domu',
      zipLabel: 'PSČ',
      country: 'Stát',
      cityLabel: 'Město',
    },
    cartNotes: {
      shippingLabel: 'Přidejte poznámku dopravci',
      shippingNote: 'Poznámka pro dopravce',
      sellerLabel: 'Přidejte poznámku pro prodejce',
      sellerNote: 'Poznámka pro prodejce',
      loginNote: 'Pokud u nás již máte účet, formulář se vyplní automaticky po přihlášení.',
    },
    cartCountryShipping: {
      title: 'ZEMĚ',
      label: 'Výběr země může ovlivit platbu, dopravu i termín dodání',
    },
    cartAddress: {
      bllingAddress: 'Fakturační adresa',
      deliveryAddress: 'Doručovací adresa',
      pickUpPointAddress: 'Adresa výdejního místa',
    },
    cartAgreements: {
      customerVerified:
        'Nesouhlasím se zasíláním dotazníku, který je součástí programu "Ověřeno zákazníky", který je prováděn za účelem měření spokojenosti zákazníků a za účelem zkvalitnění našich služeb."',
    },
    loginDialog: {
      username: 'Uživatelské jméno',
      password: 'Heslo',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Potvrďte heslo',
      invalidPostalCode: 'Zadané PSČ není v platném formátu',
      usernameIsNotFree: 'Uživatel s tímto jménem je již zaregistrován.',
      emailEmpty: 'Email je správný!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Přidat nový účet',
        all: 'Všechny účty',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Číslo',
            invoiceDate: 'Datum faktury',
            dueDate: 'Dne',
            total: 'Bez DPH',
            totalWithTax: 'Celkem',
            billingAddress: 'Fakturační adresa',
            orderNumber: 'Číslo objednávky',
            unpaidAmount: 'Nezaplacená částka',
          },
          filter: {
            number: 'Číslo',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Řádky účtu',
          columns: {
            priceUnit: 'Cena',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Celková částka',
      tax: 'DPH',
      withoutTax: 'Celková cena bez DPH',
    },
    compare: {
      addToCompare: 'Produkt byl přidán do porovnání',
      removeToCompare: 'Produkt byl odstraněn z porovnání',
      price: 'Cena',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Produkt byl přidán do oblíbených',
      removeFromFavorite: 'Produkt byl odebrán z oblíbených',
    },
    registration: {
      important: 'DŮLEŽITÉ!',
      note: 'Jednorázové heslo vám zašleme e-mailem. Po přihlášení si změňte heslo.',
      gdprAgreement: 'Musíte souhlasit se zpracováním osobních údajů',
      submitButton: 'Registrovat',
      fail: 'Registrace nebyla úspěšná, zkuste to znovu později',
      success: 'Registrace byla úspěšná',
      title: 'Registrace',
      dialog: 'Pokud u nás ještě nemáte účet, můžete se zaregistrovat',
      here: 'zde',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Hledat produkty',
    },
    appBarActions: {
      about: 'Vše o nákupu',
      aboutHref: '/vse-o-nakupu',
      compare: 'Porovnání',
    },
    appBarActionUser: {
      title: 'Přihlášení',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. - všechna práva vyhrazena',
    },
    appArticlesPage: {
      title: 'Články',
    },
    errorPage: {
      title: 'Něco je špatně.',
      button: 'Hlavní strana',
    },
    cartSummary: {
      withoutTax: 'bez DPH',
    },
    renewPasswordForm: {
      success: 'Na vaši e-mailovou adresu bylo zasláno nové heslo.',
      emailNotFound: 'E-mail nebyl nalezen!',
      error: 'Něco je špatně',
    },
    cartSubjectListItemQuantityWarning: 'Maximální množství objednávky je {count} produktů',
  },
};
export default messages;
