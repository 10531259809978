const messages = {
  cs: {
    orders: {
      actions: {
        addNewOrder: 'Nová objednávka',
        all: 'Všechny objednávky',
      },
      listPage: {
        title: 'Objednávky',
        table: {
          filter: {
            number: 'Číslo',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Datum objednávky',
            number: 'Číslo',
            offer: 'Nabídka',
            opportunity: 'Příležitost',
            owner: 'Vlastník',
            state: 'Země',
            total: 'Celkem',
            totalUserCurrency: 'Celkem v {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nová objednávka',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Celková provize',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Měna',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Datum objednávky',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Číslo',
          helperText: 'Pro automatické doplnění čísla ponechte prázdné.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Nabídka',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Příležitost',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Vlastník',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Marže',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Název',
          discount: {
            label: 'Sleva',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Provize',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Balení',
          },
          listPrice: {
            label: 'Ceníková cena',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Cena',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produkt',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Množství',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Marže',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Celkem',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Ks',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {žádné produkty} one {1 produkt} few {{number} produktů} other {{number} produkty}}',
      },
      myOrdersScoringBlog: {
        title: 'Moje objednávky',
      },
      ordersList: 'Objednávky',
    },
  },
};
export default messages;
