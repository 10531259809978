const messages = {
  cs: {
    shoppingCart: {
      actions: {
        addToCart: 'Přidat do košíku',
        buy: 'Koupit',
        order: 'Objednat',
        remove: 'Odstranit',
      },
      cart: {
        total: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Celkem k zaplacení',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'DPH',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Resetujte košík',
      },
      messages: {
        confirmResetCart: 'Opravdu chcete smazat obsah košíku?',
        giftClaim: 'Můžete získat {quantity} kusů {productName} jako dárek.',
        cartManipulations: {
          allRemoved: 'Všechny položky byly odstraněny z košíku.',
          itemAdded: '{number, plural, one {položka přidána do košíku} other {položky přidány do košíku}}.',
          itemRemoved: '{number, plural, one {Položka odstraněna z košíku} other {Položky odstraněny z košíku}}.',
          quantityChaged: 'Množství se změnilo.',
        },
      },
      components: {
        appBartActionCart: 'Košík',
        quantityField: {
          states: {
            quantityRounded: 'Množství bylo zaokrouhleno na nejbližší velikost balení.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Váš nákupní košík je prázdný',
        },
        cartAfterAddModal: {
          continueShopping: 'Pokračovat v nákupu',
          goToCart: 'Přejít do košíku',
          title: 'Produkty byly přidány do košíku',
          pcs: 'ks',
        },
      },
    },
  },
};
export default messages;
