const messages = {
  cs: {
    app: {
      about: 'O aplikaci',
      actions: {
        add: 'Přidat',
        addNamed: 'Přidat {name}',
        remove: 'Smazat',
        removeNamed: 'Smazat {name}',
        removeConfirmation: 'Jste si jistí, že to chcete smazat?',
        more: 'Více',
        less: 'Méně',
        download: 'Download',
        save: 'Uložit',
        cancel: 'Zrušit',
        close: 'Zavřít',
        moreFormFields: 'Další pole',
        export: 'Export',
        edit: 'Upravit',
        createdSuccessMessage: 'Úspěšně vytvořeno.',
        savedSuccessMessage: 'Úspěšně uloženo.',
        deletedSuccessMessage: 'Úspěšně smazáno.',
        detail: 'Detail',
        next: 'Další',
        back: 'Zpět',
        start: 'Začátek',
        whatNext: 'Kam dál',
        confirm: 'Potvrdit',
        confirmRemove: 'Jste si jistí, že to chcete smazat?',
        search: 'Vyhledávání',
        userProfile: 'Uživatelský profil',
        tenantProfile: 'Váš CRM profil',
        imports: 'Import',
        fileImports: 'Importovat soubory',
        settings: 'Nastavení',
        logout: 'Odhlásit',
        login: 'Přihlásit',
        filterList: 'Filtry',
        excel: 'Excel',
        refresh: 'Resetovat',
        clear: 'Vymazat',
        agree: 'souhlasím',
        disagree: 'Nesouhlasím',
        makeCopy: 'Kopírovat',
        fullscreen: 'Zobrazit na celé obrazovce',
        fullscreenClose: 'Ukončit zobrazení na celou obrazovku',
        copy: 'kopírovat',
      },
      messages: {
        confirmRemove: 'Opravdu chcete tento produkt smazat?',
        errorOccurred: 'Vyskytla se chyba',
        createdSuccess: 'Vytvoření bylo úspěšné.',
        savedSuccess: 'Uložení byla úspěšné.',
        deletedSuccess: 'Smazání bylo úspěšné.',
        uploadSuccess: 'Soubor byl úspěšně nahrán.',
        uploadError: 'Chyba při nahrávání souboru.',
        uploadInProgress: 'Načítání...',
        uploadedFiles:
          '{number, plural, one {1 soubor nahrán} few {{number} nahrané soubory} otherch {{number} souborů bylo nahráno}}',
      },
      error: 'Error',
      validations: {
        isRequired: 'Pole {field} je povinné.',
        minLengthIsRequired:
          'Je vyžadováno alespoň {number, plural, one {1 položka} few {{number} položky} otherch {{number} produktů}}.',
        minValue: 'Maximální povolená hodnota je {value}.',
        maxValue: 'Maximální povolená hodnota je {value}.',
        passwordMatchError: 'Hesla se neshodují',
        invalidEmail: 'E-mail je neplatný.',
        invalidPhoneNumber: "Telefonní číslo musí být v platném mezinárodním formátu! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Neplatné číslo',
        invalidUrl: 'Neplatná adresa URL.',
        positive: 'Číslo musí mít kladnou hodnotu.',
        negative: 'Číslo musí mít zápornou hodnotu.',
      },
      login: {
        title: 'Přihlaste se do Sprinx CRM',
        username: 'Uživatelské jméno',
        password: 'Heslo',
        loginButton: 'Přihlášení',
        error: 'Přihlášení nebylo úspěšné. Uživatelské jméno nebo heslo nebylo nalezeno',
        pleaseRelogin: 'Zdá se, že nejste přihlášeni. Zkuste se prosím přihlásit znovu.',
      },
      days: '{count, plural, one {1 den} few {{formattedCount} dní} other {{formattedCount} dni}}',
      overdue: 'zpoždění {count, plural, one {1 den} few {{formattedCount} dní} other {{formattedCount} dni}}',
      newItem: 'Nový',
      anonymizing: {
        confirm: {
          title: 'Potvrďte anonymizaci',
          content: 'Opravdu chcete anonymizovat osobní údaje?',
        },
        button: 'Anonymizace osobních údajů',
      },
      appMenu: {
        discover: 'Objevit',
      },
      missingType: 'Nejprve prosím zadejte {typeName} v administraci aplikace.',
      missingTypeForEntityType: 'Nejprve prosím definujte {typeName} pro {forEntityTypeName} v administraci aplikace.',
      emptyScoringBlog: 'Moduly, jejichž obsah nebo nastavení jsou zobrazeny na této stránce, nejsou aktivovány.',
    },
    components: {
      buttons: {
        showOnMap: 'Zobrazit na mapě',
      },
      filters: {
        filterButtonSubmit: 'Vyhledávání',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Přidat podmínku',
        resetFilter: 'Resetujte filtr',
        operators: {
          contains: ['obsahuje'],
          containsexact: ['přesně obsahuje'],
          notcontains: ['neobsahuje'],
          betweennumber: ['od', 'do'],
          betweendate: ['od', 'do'],
          equal: ['je stejný'],
        },
      },
      paginationList: {
        itemsTotal: 'Celkový počet produktů: {number}',
        nextPage: 'Seznam produktů: {number}',
        emptyMessage: 'Nebyly nalezeny žádné produkty.',
      },
      selects: {
        emptyOption: 'Prázdné',
        emptyMessage: 'Nebyly nalezeny žádné produkty.',
      },
      tables: {
        labelRowsPerPage: 'Řádek na stránce',
        sortBy: 'Seřadit podle {field}',
        recordsChanged: 'Některé záznamy byly přidány nebo změněny.',
      },
      textField: {
        errorMsgWrapper: '{msg}',
      },
      dateField: {
        invalidDateFormat: 'Nepravidelný formát data.',
        invalidDate: 'Chybné datum',
        maxDateMessage: 'Datum nemůže být větší než maximální datum',
        minDateMessage: 'Datum nesmí být kratší než minimální datum',
        today: 'Dnes',
      },
      detailNavigation: {
        scoringblog: 'Scoring blog',
        card: 'Karta',
        relations: 'Být',
      },
      manipulationInfo: {
        created: 'Vytvořeno',
        updated: 'Změněno',
        removed: 'Smazáno',
      },
    },
  },
};
export default messages;
