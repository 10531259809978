const messages = {
  cs: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Košík',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametry',
        },
      },
      productAvailability: {
        format: {
          available: 'Skladem',
          moreThanX: 'Více než {min} kusů skladem',
          exactCount: '{value} kusů skladem',
          notAvailable: 'Není k dispozici',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ dalších ({number}).',
      },
      relatedProducts: {
        title: 'Související produkty',
      },
      productAccessories: {
        title: 'Příslušenství',
      },
      productDetailImageGallery: {
        noImage: 'Žádné obrázky',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kdy obdržím zboží?',
      },
    },
  },
};
export default messages;
