const messages = {
  cs: {
    sprinxFormsValidation: {
      required: 'Políčko je vyžadováno!',
      string: 'Musí to být textový řetězec!',
      stringEmpty: 'Nemůže být prázdné',
      stringMin: 'Délka musí být větší nebo rovna {expected} znakům!',
      stringMax: 'Délka musí být menší nebo rovna {expected} znakům!',
      stringLength: 'Délka musí mít {expected} znaků!',
      stringPattern: 'Neodpovídá požadovanému vzoru!',
      stringContains: "Je třeba zahrnout text '{expected}'!",
      stringEnum: 'Neodpovídá žádné z povolených hodnot!',
      stringNumeric: 'To musí být číslo!',
      number: 'To musí být číslo!',
      numberMin: 'Musí být větší nebo rovno {expected}!',
      numberMax: 'Musí být menší nebo roven {expected}!',
      numberEqual: 'Musí být stejné jako {expected}!',
      numberNotEqual: 'Nesmí být stejné jako {expected}!',
      numberInteger: 'Musí to být celé číslo!',
      numberPositive: 'Číslo musí mít kladnou hodnotu!',
      numberNegative: 'Číslo musí mít zápornou hodnotu.',
      array: 'Musí být seznam!',
      arrayEmpty: 'Nesmí být prázdný seznam!',
      arrayMin: 'Musí být alespoň {number, plural, one {1 produkt} few {{number} produktů} other {{number} produktů}}!',
      arrayMax:
        'Nemůže obsahovat více než {number, plural, one {1 produkt} few {{number} produktů} other {{number} produktů}}!',
      arrayLength:
        'Musí obsahovat přesně {expected, plural, one {1 produkt} few {{number} produktů} other {{number} produktů}}!',
      arrayContains: "Musí obsahovat produkt '{expected}'!",
      arrayEnum: "Hodnota '{expected}' neodpovídá žádné z povolených hodnot!",
      boolean: 'Musí to být „ano“ nebo „ne“!',
      function: 'Musí být funkce!',
      date: 'Musí být datum!',
      dateMin: 'Musí být větší nebo rovno {expected}!',
      dateMax: 'Musí být menší nebo rovno {expected}!',
      forbidden: 'Zakázáno!',
      email: 'Email je správný!',
      url: 'Neplatná adresa URL!',
      object: 'Musí být typu Objekt!',
      arrayReducedEquals: 'Souhrnná hodnota neodpovídá {expected}!',
      phoneNumber: "Telefonní číslo musí být v platném mezinárodním formátu! '+ XXX XXX XXX XXX '!",
      dateGt: 'Datum musí být větší než {expected}!',
      dateGte: 'Datum musí být větší nebo rovno {expected}!',
      dateLt: 'Datum musí být kratší než {expected}!',
      dateLte: 'Datum musí být menší nebo rovno {expected}!',
    },
  },
};
export default messages;
