const messages = {
  cs: {
    reactMuiTables: {
      selected: 'vybraný',
      filterhide: 'Skrýt filtr',
      filtershow: 'Zobrazit filtr',
      refresh: 'Obnovit',
      filter: {
        actions: {
          search: 'Vyhledávání',
          resetFilter: 'Smazat filtr',
          addCondition: 'Přidat podmínku',
        },
      },
      standardOperators: {
        equals: 'rovná se',
        contains: 'obsahuje',
        between: {
          label: 'mezi',
          from: 'od',
          to: 'do',
        },
        oneOf: 'jeden z',
      },
      labelRowsPerPage: 'Počet řádků na stránku:',
    },
  },
};
export default messages;
